@import "bootstrap/dist/css/bootstrap.min.css";
@import "@mdi/font/css/materialdesignicons.min.css";
@import "./styles/animations.scss";
@import "./styles/bootstrap-overrides.scss";
@import "./styles/typography.scss";

html {
	height: 100%;
}

body {
	height: 100%;
	margin: 0;
	font-family: RedHatText, "Helvetica Neue", sans-serif;
	font-size: 16px;
}

#root {
	height: 100%;
}
