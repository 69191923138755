@font-face {
	font-family: "Peak-Semibold";
	src: url(../assets/fonts/peak-semibold/Peak-Semibold.woff) format("woff"),
		url(../assets/fonts/peak-semibold/Peak-Semibold.woff2) format("woff2"),
		url(../assets/fonts/peak-semibold/Peak-Semibold.ttf) format("truetype"),
		url(../assets/fonts/peak-semibold/Peak-Semibold.otf) format("otf");
	font-weight: 600;
	font-style: normal;
	font-display: block;
}

@font-face {
	font-family: "RedHatText";
	src: url(../assets/fonts/red-hat-text/RedHatText-Regular.ttf)
		format("truetype");
	font-weight: 400;
	font-style: normal;
	font-display: block;
}

@font-face {
	font-family: "RedHatText";
	src: url(../assets/fonts/red-hat-text/RedHatText-Medium.ttf)
		format("truetype");
	font-weight: 500;
	font-style: normal;
	font-display: block;
}

@font-face {
	font-family: "RedHatText";
	src: url(../assets/fonts/red-hat-text/RedHatText-Bold.ttf)
		format("truetype");
	font-weight: 700;
	font-style: normal;
	font-display: block;
}

h1,
h2,
h3,
h4,
h5,
h6,
p {
	font-size: 100%;
	font: inherit;
	vertical-align: baseline;
	margin: 0;
}
