.modal-content {
	border-radius: 12px;
	overflow: hidden;
}

.modal-backdrop.show {
	opacity: 0.7;
}

// core/components/ViewOverlay
.view-overlay-backdrop,
.view-overlay-backdrop.show {
  background: white;
  opacity: 1;

  @media (min-width: 768px) {
    background: black;
    opacity: 0.7;
  }
}

.tip-modal-backdrop {
  z-index: 1055;
}

.add-card-modal-backdrop {
  z-index: 1055;
}

.cart-issues-modal-backdrop {
  z-index: 1055;
}

.create-cart-modal-backdrop {
  z-index: 1055;
}

.form-check-input {
  &:checked {
    background-color: #41CE83;
    border-color: #41CE83;
  }

  &:focus {
    box-shadow: none;
  }
}

